import { Alert, Button, Card, Form, Input, Spin, message } from 'antd';
import { Store } from 'antd/lib/form/interface';
import clsx from 'clsx';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  PRIVACY_POLICY_ENDPOINT,
  SERVER_APPLE_AUTH_LINK,
  SERVER_FACEBOOK_AUTH_LINK,
  SERVER_GOOGLE_AUTH_LINK,
  SERVER_REGISTER_ENDPOINT,
  TERMS_OF_SERVICE_ENDPOINT,
} from '../../configs/endpoints';
import Logo from '../../logo/Logo';
import { POST, axioService } from '../../services/axioService';
import { getSessionToken } from '../../store/ducks/session';

/** interface to describe register props */
interface RegisterProps {
  token: string;
}

/** the Register component */
const Register: React.FC<RegisterProps> = (props: RegisterProps) => {
  const { token } = props;
  const location = useLocation();
  const history = useHistory();
  const nameRef = React.useRef<any>(null);
  const passwordRef = React.useRef<any>(null);
  const passwordConfirmationRef = React.useRef<any>(null);
  const [isNameFocused, setIsNameFocused] = useState<boolean>(false);
  const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState<
    boolean
  >(false);

  // React states
  /** manages loading while processing register request */
  const [loading, setLoading] = React.useState<boolean>(false);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  /** status to manage password visibility */
  const [isPasswordVisible, setIsPasswordVisible] = React.useState<boolean>(
    false
  );

  /** cycles */

  React.useEffect(() => {
    nameRef.current?.focus();
  }, []);

  /** handlers */

  /** status to manage password confirmation visibility */
  const [
    isPasswordConfirmationVisible,
    setIsPasswordConfirmationVisible,
  ] = React.useState<boolean>(false);

  /** toggles the password input visibility */
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    passwordRef.current.blur();
  };

  /** toggles the password confirmation input visibility */
  const handlePasswordConfirmationVisibility = () => {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
    passwordConfirmationRef.current.blur();
  };

  /**
   * onFinish is called when form validation passes
   * @param {any} values - containing the key-value user response
   */
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const response = await axioService(
        POST,
        SERVER_REGISTER_ENDPOINT,
        {
          name: values.name,
          email: values.email,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
        false
      );
      /** redirects on successful login */
      message.success(
        'Registration Successful, Please check your email to verify'
      );
      if (response.status === 201) {
        history.push(LOCAL_LOGIN_ENDPOINT + location.search);
      }
      setLoading(false);
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
      setLoading(false);
      if (
        (exception as any).response &&
        (exception as any).response.data &&
        (exception as any).response.data.message
      ) {
        /** create the error msg from server response */
        const errorMsg = (
          <div>
            {(exception as any).response.data.message} <br />
            <ul>
              {(exception as any).response.data.errors &&
                Object.keys((exception as any).response.data.errors).map(
                  (iterErrorField) => (
                    <li key="iterErrorField">
                      <span className="Form-alert-field">{iterErrorField}</span>{' '}
                      :{' '}
                      {(exception as any).response.data.errors[iterErrorField]}
                    </li>
                  )
                )}
            </ul>
          </div>
        );
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage('There is a network error !!!');
      }
    }
  };

  /**
   * onFinishFailed is called when form validation fails
   * @param {any} errorInfo - containing the respective error information
   */
  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.error('Failed:', errorInfo);
  };

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  /** google login handler */
  const googleLoginHandler = () => {
    window.location.href = SERVER_GOOGLE_AUTH_LINK;
  };

  /** facebook login handler */
  const facebookLoginHandler = () => {
    window.location.href = SERVER_FACEBOOK_AUTH_LINK;
  };

  /** apple login handler */
  const appleLoginHandler = () => {
    window.location.href = SERVER_APPLE_AUTH_LINK;
  };

  return (
    <Spin spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Form-error"
          message="Registration Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <div className="Form-body">
        <div className="Form-content">
          {token !== '' && <Redirect to={LOCAL_PERSONAL_INFO_ENDPOINT} />}
          <div className="Form-logo-container">
            <Logo />
          </div>
          <Form
            name="register"
            validateTrigger="onSubmit"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="Form-card">
              <h4 className="Form-card-title">Create a new account</h4>
              <Form.Item
                className={clsx(
                  'Form-card-item',
                  isNameFocused && 'Form-card-item-focused'
                )}
                name="name"
                rules={[{ required: true, message: 'Please input a name' }]}
              >
                <Input
                  ref={nameRef}
                  size="large"
                  onFocus={() => setIsNameFocused(true)}
                  onBlur={() => setIsNameFocused(false)}
                  placeholder={!isNameFocused ? 'Write your name' : ''}
                  prefix={<img src="user.svg" alt="user" />}
                />
              </Form.Item>
              <Form.Item
                className={clsx(
                  'Form-card-item',
                  isEmailFocused && 'Form-cardd-item-focused'
                )}
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please input valid email!' },
                ]}
              >
                <Input
                  size="large"
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                  placeholder={
                    !isEmailFocused ? 'Enter your email address' : ''
                  }
                  prefix={<img src="/envelop.png" alt="icon" />}
                />
              </Form.Item>
              <Form.Item
                className={clsx(
                  'Form-card-item',
                  isPasswordFocused && 'Form-card-item-focused'
                )}
                name="password"
                rules={[
                  { required: true, message: 'Please input a password!' },
                  {
                    min: 8,
                    message: 'Password needs to be minimum 8 characters!',
                  },
                ]}
              >
                <Input
                  size="large"
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  placeholder={!isPasswordFocused ? 'Password' : ''}
                  prefix={<img src="/key.svg" alt="icon" />}
                  type={isPasswordVisible ? 'text' : 'password'}
                  ref={passwordRef}
                  suffix={
                    !isPasswordVisible ? (
                      <i
                        onClick={handlePasswordVisibility}
                        className="fas fa-eye-slash"
                      />
                    ) : (
                      <i
                        className="fas fa-eye"
                        onClick={handlePasswordVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                className={clsx(
                  'Form-card-item mb-0',
                  isConfirmPasswordFocused && 'Form-card-item-focused'
                )}
                name="password_confirmation"
                rules={[
                  { required: true, message: 'Please confirm the password!' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  onFocus={() => setIsConfirmPasswordFocused(true)}
                  onBlur={() => setIsConfirmPasswordFocused(false)}
                  placeholder={
                    !isConfirmPasswordFocused ? 'Confirm Password' : ''
                  }
                  prefix={<img src="/key.svg" alt="icon" />}
                  type={isPasswordConfirmationVisible ? 'text' : 'password'}
                  ref={passwordConfirmationRef}
                  suffix={
                    isPasswordConfirmationVisible ? (
                      <i
                        onClick={handlePasswordConfirmationVisibility}
                        className="fas fa-eye-slash"
                      />
                    ) : (
                      <i
                        className="fas fa-eye"
                        onClick={handlePasswordConfirmationVisibility}
                      />
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                extra={
                  <div className="Form-text-extra">
                    By creating an account, you agree with our{' '}
                    <a target="blank" href={TERMS_OF_SERVICE_ENDPOINT}>
                      terms of service
                    </a>{' '}
                    and{' '}
                    <a target="blank" href={PRIVACY_POLICY_ENDPOINT}>
                      privacy policy
                    </a>
                    .
                  </div>
                }
              ></Form.Item>
              <Button
                className="Form-submit-btn"
                type="primary"
                htmlType="submit"
                size="large"
              >
                Sign up
              </Button>
              <div className="Form-divider-container">
                <div className="Form-divider" /> <h4> Or </h4>
                <div className="Form-divider" />
              </div>
              <Button
                className="Form-btn-or"
                size="large"
                onClick={googleLoginHandler}
              >
                <div className="Form-btn-content">
                  <span className="Form-btn-icon">
                    <img src="google.svg" alt="Icon" />
                  </span>
                  <span className="Form-btn-label">Sign up with Google</span>
                </div>
              </Button>
              {/* <Button
                className="Form-btn-or"
                size="large"
                onClick={facebookLoginHandler}
              >
                <div className="Form-btn-content">
                  <span className="Form-btn-icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.9998 11.0673C21.9998 4.95403 17.0746 -0.00146484 10.9998 -0.00146484C4.92232 -8.9844e-05 -0.00292969 4.95403 -0.00292969 11.0687C-0.00292969 16.592 4.02032 21.1708 9.27832 22.0013V14.2669H6.48707V11.0687H9.28107V8.62803C9.28107 5.85466 10.9242 4.32291 13.4363 4.32291C14.6408 4.32291 15.8989 4.53878 15.8989 4.53878V7.26128H14.5116C13.1462 7.26128 12.7199 8.11516 12.7199 8.99103V11.0673H15.7697L15.2829 14.2655H12.7186V21.9999C17.9766 21.1694 21.9998 16.5907 21.9998 11.0673Z"
                        fill="#1877F2"
                      />
                    </svg>
                  </span>
                  <span className="Form-btn-label">Sign up with Facebook</span>
                </div>
              </Button> */}
              <Button
                className="Form-btn-or"
                size="large"
                onClick={appleLoginHandler}
              >
                <div className="Form-btn-content">
                  <span className="Form-btn-icon">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3752 0.0110096C13.3284 -0.0412404 11.644 0.0316347 10.1783 1.62251C8.71252 3.21201 8.93802 5.03526 8.97102 5.08201C9.00402 5.12876 11.061 5.20163 12.3742 3.35226C13.6873 1.50288 13.4219 0.0646346 13.3752 0.0110096ZM17.9319 16.1439C17.8659 16.0119 14.735 14.4471 15.0265 11.4386C15.318 8.42876 17.3297 7.60376 17.3613 7.51438C17.3929 7.42501 16.5404 6.42813 15.637 5.92351C14.9737 5.56773 14.2397 5.36389 13.4879 5.32676C13.3394 5.32263 12.8238 5.19613 11.7636 5.48626C11.0652 5.67738 9.49077 6.29613 9.05765 6.32088C8.62315 6.34563 7.33065 5.60313 5.94052 5.40651C5.0509 5.23463 4.10765 5.58663 3.43252 5.85751C2.75877 6.12701 1.47727 6.89426 0.580773 8.93338C-0.315727 10.9711 0.153148 14.1996 0.488648 15.2034C0.824148 16.2058 1.34802 17.8489 2.23902 19.0479C3.03102 20.4009 4.08152 21.34 4.52015 21.659C4.95877 21.978 6.19627 22.1898 7.05427 21.7511C7.74452 21.3276 8.99027 21.0843 9.48252 21.1021C9.9734 21.12 10.9414 21.3139 11.9328 21.8433C12.7179 22.1141 13.4604 22.0014 14.2043 21.6989C14.9481 21.395 16.0248 20.2428 17.2815 17.9066C17.7586 16.8204 17.9759 16.2333 17.9319 16.1439Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span className="Form-btn-label">Sign up with Apple</span>
                </div>
              </Button>
              <div className="Form-register-options-container">
                <h4>
                  <span className="Form-extra-label">
                    Already have an account?{' '}
                  </span>
                  <Link to={LOCAL_LOGIN_ENDPOINT + location.search}>
                    Sign in
                  </Link>
                </h4>
              </div>
            </Card>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect Register to the redux store */
const ConnectedRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);

export default ConnectedRegister;
