import React from 'react';
import { VIRTUNUS_HOME_ENDPOINT } from '../configs/endpoints';

export default function Logo() {
  return (
    <>
      <a href={VIRTUNUS_HOME_ENDPOINT}>
        <img src="/logo.svg" width="120" alt="Logo" />
      </a>
    </>
  );
}
