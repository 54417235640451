import React from 'react';
import './App.scss';
import Login from '../containers/login/Login';
import Register from '../containers/register/Register';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import ConnectedPrivateRoute from '../containers/privateRoute/PrivateRoute';
import Account from '../containers/account/Account';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ConnectedSessionDestroy from '../containers/sessionDestroy/SessionDestroy';
import {
  APP_AUTHORIZE_ENDPOINT,
  LOCAL_AUTHORIZE_ENDPOINT,
  LOCAL_FORGOT_PASSWORD_ENDPOINT,
  LOCAL_LOGIN_ENDPOINT,
  LOCAL_LOGOUT_ENDPOINT,
  LOCAL_PERSONAL_INFO_ENDPOINT,
  LOCAL_REGISTER_ENDPOINT,
  LOCAL_RESET_PASSWORD_ENDPOINT,
  LOCAL_VERIFY_EMAIL_ENDPOINT,
  SERVER_USER_PROFILE_ENDPOINT,
} from '../configs/endpoints';
import { Store } from 'redux';
import { getSessionToken, setSessionUserInfo } from '../store/ducks/session';
import { connect } from 'react-redux';
import { axioService, GET } from '../services/axioService';
import ConnectedAccountRecovery from '../containers/accountRecovery/AccountRecovery';
import ConnectedResetPassword from '../containers/resetPassword/ResetPassword';
import ConnectedSessionAuthorize from '../containers/sessionAuthorize/SessionAuthorize';
import ConnectedVerifyEmail from '../containers/verifyEmail/VerifyEmail';
import { getDomain } from '../services/cookieStorageSync';

interface AppProps {
  token: string;
  setSessionUserInfoActionCreator: typeof setSessionUserInfo;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { token, setSessionUserInfoActionCreator } = props;
  const history = useHistory();

  /** sets the theme colors */
  React.useEffect(() => {
    // sets the theme colors
    document.body.style.setProperty('--theme-dark', '#2777f4');
    document.body.style.setProperty('--theme-primary', '#1f89f6');
    document.body.style.setProperty('--theme-light', '#1897f8');
    document.body.style.setProperty('--theme-hover-light', '#dde4fc');
  }, []);

  /** fetches initial data based on login */
  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_USER_PROFILE_ENDPOINT,
          {},
          true
        );
        setSessionUserInfoActionCreator(response.data.data);
        afterGettingUser();
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };
    if (token !== '') {
      fetchProfile();
    }
  }, [token]);

  const afterGettingUser = () => {
    const app = window.sessionStorage.getItem('app');
    const redirectEndpoint = window.sessionStorage.getItem('redirect');
    if (app !== '') {
      if (app === 'devtipslocal') {
        window.location.href = `http://localhost:3000/authorize?token=${token}`;
      } else {
        const domain = getDomain(window.location.hostname, false);
        window.location.href =
          APP_AUTHORIZE_ENDPOINT !== ''
            ? `${APP_AUTHORIZE_ENDPOINT}?redirect=${redirectEndpoint}`
            : `https://${app}.${domain}?redirect=${redirectEndpoint}`;
      }
    } else {
      history.push(LOCAL_PERSONAL_INFO_ENDPOINT);
    }
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path={LOCAL_LOGIN_ENDPOINT}>
          <Login />
        </Route>
        <Route path={LOCAL_REGISTER_ENDPOINT}>
          <Register />
        </Route>
        <Route path={LOCAL_LOGOUT_ENDPOINT}>
          <ConnectedSessionDestroy />
        </Route>
        <Route path={LOCAL_AUTHORIZE_ENDPOINT}>
          <ConnectedSessionAuthorize />
        </Route>
        <Route path={LOCAL_FORGOT_PASSWORD_ENDPOINT}>
          <ConnectedAccountRecovery />
        </Route>
        <Route path={LOCAL_RESET_PASSWORD_ENDPOINT}>
          <ConnectedResetPassword />
        </Route>
        <ConnectedPrivateRoute
          path={LOCAL_PERSONAL_INFO_ENDPOINT}
          component={Account}
        />
        <ConnectedPrivateRoute
          path={LOCAL_VERIFY_EMAIL_ENDPOINT}
          component={ConnectedVerifyEmail}
        />
        <Route>
          <Redirect to={LOCAL_LOGIN_ENDPOINT} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionUserInfoActionCreator: setSessionUserInfo,
};

/** connect App to the redux store */
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

/** the default export */
export default ConnectedApp;
