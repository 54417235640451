/** local */

/** local login endpoint */
export const LOCAL_LOGIN_ENDPOINT = `/login`;

/** local register endpoint */
export const LOCAL_REGISTER_ENDPOINT = `/register`;

/** local forgot password endpoint */
export const LOCAL_FORGOT_PASSWORD_ENDPOINT = `/forgot-password`;

/** local reset password endpoint */
export const LOCAL_RESET_PASSWORD_ENDPOINT = `/reset-password`;

/** local logout endpoint */
export const LOCAL_LOGOUT_ENDPOINT = `/logout`;

/** local logout endpoint */
export const LOCAL_AUTHORIZE_ENDPOINT = `/authorize`;

/** local verify email endpoint */
export const LOCAL_VERIFY_EMAIL_ENDPOINT = `/email/verify/:id/:hash`;

/** local personal info endpoint */
export const LOCAL_PERSONAL_INFO_ENDPOINT = `/account/personal-info`;

/** server */

/** server register endpoint */
export const SERVER_REGISTER_ENDPOINT =
  process.env.REACT_APP_REGISTER_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/register`;

/** server login endpoint */
export const SERVER_LOGIN_ENDPOINT =
  process.env.REACT_APP_LOGIN_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`;

/** server login endpoint */
export const SERVER_LOGOUT_ENDPOINT =
  process.env.REACT_APP_LOGOUT_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/logout`;

/** server forgot password endpoint */
export const SERVER_FORGOT_PASSWORD_ENDPOINT =
  process.env.REACT_APP_FORGOT_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/forgot-password`;

/** server reset password endpoint */
export const SERVER_RESET_PASSWORD_ENDPOINT =
  process.env.REACT_APP_RESET_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/reset-password`;

/** server change password endpoint */
export const SERVER_CHANGE_PASSWORD_ENDPOINT =
  process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/change-password`;

/** server profile endpoint */
export const SERVER_USER_PROFILE_ENDPOINT =
  process.env.REACT_APP_USER_PROFILE_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/basic`;

/** server email verify endpoint */
export const SERVER_EMAIL_VERIFY_ENDPOINT =
  process.env.REACT_APP_EMAIL_VERIFY_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verify`;

/** server send email endpoint */
export const SERVER_SEND_EMAIL_ENDPOINT =
  process.env.REACT_APP_SEND_EMAIL_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verification-notification`;

/** server avatar endpoint */
export const SERVER_AVATAR_ENDPOINT =
  process.env.REACT_APP_AVATAR_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/change-avatar`;

/** auth endpoints */

/** google auth endpoint */
export const SERVER_GOOGLE_AUTH_LINK =
  process.env.REACT_APP_GOOGLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/google`;

/** facebook auth endpoint */
export const SERVER_FACEBOOK_AUTH_LINK =
  process.env.REACT_APP_FACEBOOK_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/facebook`;

/** apple auth endpoint */
export const SERVER_APPLE_AUTH_LINK =
  process.env.REACT_APP_APPLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/apple`;

/** apps */

/** Todo app endpoint */
export const TODO_APP_ENDPOINT = process.env.REACT_APP_TODO_ENDPOINT || '';

/** Tips app endpoint */
export const TIPS_APP_ENDPOINT = process.env.REACT_APP_TIPS_ENDPOINT || '';

/** sample app authorize endpoint */
export const APP_AUTHORIZE_ENDPOINT =
  process.env.REACT_APP_AUTHORIZE_ENDPOINT || '';

/** external */
export const BLOG_ENDPOINT = process.env.REACT_APP_BLOG_ENDPOINT || '';
export const TERMS_OF_SERVICE_ENDPOINT =
  process.env.REACT_APP_TERMS_OF_SERVICE_ENDPOINT ||
  'https://virtunus.com/terms-of-service/';
export const PRIVACY_POLICY_ENDPOINT =
  process.env.REACT_APP_PRIVACY_POLICY_ENDPOINT ||
  'https://virtunus.com/privacy-policy/';
export const VIRTUNUS_HOME_ENDPOINT =
  process.env.REACT_APP_VIRTUNUS_HOME_ENDPOINT || 'https://app.upstride.co/';
