import { Alert, Avatar, Dropdown } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_SEND_EMAIL_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import { FlexObj, getSessionUserInfo } from '../../store/ducks/session';
import AccountLogoutDropdown from '../accountLogoutDropdown/AccountLogoutDropdown';
import AppsDropdown from '../appsDropdown/AppsDropdown';
import './Header.scss';

interface HeaderProps {
  userInfo: FlexObj;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { userInfo } = props;
  const [isEmailSend, setIsEmailSend] = React.useState<boolean>(false);

  const sendVerificationEmail = async () => {
    try {
      await axioService(POST, SERVER_SEND_EMAIL_ENDPOINT, {}, true);
      setIsEmailSend(true);
    } catch (Exception) {
      console.error(Exception);
    }
  };

  return (
    <div className="Header-container">
      <div className="Header-content">
        {!userInfo.is_email_verified && (
          <Alert
            className="Header-email-alert"
            message={
              <div>
                Email is not verified yet! Please verify your email by clicking
                on verification link.{' '}
                {!isEmailSend ? (
                  <span
                    onClick={sendVerificationEmail}
                    className="Header-send-email"
                  >
                    Send verification email
                  </span>
                ) : (
                  <span>
                    Message Sent.{' '}
                    <span
                      className="Header-send-email"
                      onClick={sendVerificationEmail}
                    >
                      Resend
                    </span>
                  </span>
                )}
                .
              </div>
            }
            banner
            closable
          />
        )}
        <div className="Header-logo-container">
          <div className="Header-logo">
            <img src="/logo.svg" width="120" alt="" />
            <div className="Header-app-font">Account</div>
          </div>
        </div>
        <div className="Header-main-content">
          <div className="Header-content-title">Settings</div>
          <Dropdown
            trigger={['click']}
            overlay={<AppsDropdown />}
            placement="bottomRight"
          >
            <div className="Header-apps">
              <i className="fas fa-th"></i>
            </div>
          </Dropdown>
          <Dropdown
            trigger={['click']}
            overlay={<AccountLogoutDropdown />}
            placement="bottomRight"
          >
            <div className="Header-profile">
              <Avatar src={userInfo.avatar}>
                <div>{userInfo?.name?.slice(0, 2)}</div>
              </Avatar>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect Header to the redux store */
const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);

export default ConnectedHeader;
